/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useProductFlow } from 'hooks/useProductFlow';

const DsTypography = dynamic(() => import('componentsV2/DS/Typography'), {
  ssr: false,
});

const PoliticsAndTerms = ({
  politicsUrl,
  termsUrl,
  isMz,
  showTerms = true,
}) => {
  const { currentStep } = useProductFlow();

  return (
    <div>
      {politicsUrl ? (
        <>
          <DsTypography component="paragraph" size="small" color="neutral-high">
            {isMz
              ? 'Li e concordo com a '
              : 'Ao informar seus dados e seguir para a próxima etapa, você automaticamente concorda com nossa '}
          </DsTypography>
          <a
            id="live-open-policy"
            href={politicsUrl}
            rel="noreferrer"
            target="_blank"
            style={{
              textDecorationColor: 'var(--color-secondary-pure)',
            }}
          >
            <DsTypography
              component="paragraph"
              size="small"
              color="secondary-pure"
            >
              Política de Privacidade
            </DsTypography>
          </a>{' '}
          {!!termsUrl && showTerms && (
            <>
              <DsTypography
                component="paragraph"
                size="small"
                color="neutral-high"
              >
                e com os{' '}
              </DsTypography>
              <a
                id="live-open-terms"
                href={termsUrl}
                rel="noreferrer"
                target="_blank"
                style={{ textDecorationColor: 'var(--color-secondary-pure)' }}
              >
                <DsTypography
                  component="paragraph"
                  size="small"
                  color="secondary-pure"
                >
                  {isMz ? 'Termos de Utilização' : 'Termos de Uso'}
                </DsTypography>
              </a>
            </>
          )}
        </>
      ) : (
        <p>
          {!!termsUrl && showTerms && (
            <>
              <DsTypography
                component="paragraph"
                size="small"
                color="neutral-high"
              >
                Li e concordo com os
              </DsTypography>

              <a
                id="live-open-terms"
                href={termsUrl}
                rel="noreferrer"
                target="_blank"
                style={{ textDecorationColor: 'var(--color-secondary-pure)' }}
              >
                <DsTypography
                  component="paragraph"
                  size="small"
                  color="secondary-pure"
                >
                  {isMz
                    ? 'Termos de Utilização'
                    : currentStep.props?.useCheckboxContractTerms
                      ? 'Termos de Contrato'
                      : 'Termos de Uso'}
                </DsTypography>
              </a>
            </>
          )}
        </p>
      )}
    </div>
  );
};

PoliticsAndTerms.defaultProps = {
  politicsUrl: '',
  termsUrl: '',
  isMz: false,
  product: '',
};

PoliticsAndTerms.propTypes = {
  politicsUrl: PropTypes.string,
  termsUrl: PropTypes.string,
  isMz: PropTypes.bool,
  product: PropTypes.string,
};

export default PoliticsAndTerms;
